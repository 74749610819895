import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';
import { Header, Nav } from './../../elements/NavigationElements';
import { Container } from './../../elements/SharedElements';


const NavigationComponent = () => {
    const isPartiallyActive = ({isPartiallyCurrent}) => {
        return isPartiallyCurrent ? { className: "active" } : {}
    }

    const [state, setState] = useState(false);

    const toggle = () => {
        setState(!state)
    }

    return (
        <Header>
            <Container>
                <Nav>
                    <div>
                        <Link to="/">Café Living</Link>
                    </div>
                    <ul className="desktop">
                        <li><Link activeClassName="active" to="/about">About</Link></li>
                        <li><Link activeClassName="active" getProps={isPartiallyActive} to="/restaurant">Restaurant</Link></li>
                        <li><Link activeClassName="active" getProps={isPartiallyActive} to="/cafe">Cafe</Link></li>
                        <li><Link activeClassName="active" getProps={isPartiallyActive} to="/bar">Bar</Link></li>
                        <li><Link activeClassName="active" getProps={isPartiallyActive} to="/guides">Guides</Link></li>
                    </ul>
                    { state ? 
                        <ul className="mobile">
                            <li><Link activeClassName="active" to="/about">About</Link></li>
                            <li><Link activeClassName="active" getProps={isPartiallyActive} to="/restaurant">Restaurant</Link></li>
                            <li><Link activeClassName="active" getProps={isPartiallyActive} to="/cafe">Cafe</Link></li>
                            <li><Link activeClassName="active" getProps={isPartiallyActive} to="/bar">Bar</Link></li>
                            <li><Link activeClassName="active" getProps={isPartiallyActive} to="/guides">Guides</Link></li>
                        </ul>
                        :
                        <FontAwesomeIcon icon={faBars} onClick={toggle} />
                    }
                    { state && <FontAwesomeIcon icon={faTimes} onClick={toggle} /> }
                </Nav>
            </Container>
        </Header>
    )
    
}

export default NavigationComponent;
import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { Footer, FooterWrapper, Copyright } from './../../elements/FooterElements';
import { Container } from './../../elements/SharedElements';

const FooterComponent = () => (
    <Footer>
        <Container>
            <FooterWrapper>
                <ul>
                    <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/cafelivingza/"><FontAwesomeIcon icon={faInstagram}/></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://twitter.com/cafeliving1"><FontAwesomeIcon icon={faTwitter}/></a></li>
                    <li><a target="_blank" rel="noreferrer" href="https://pinterest.com/cafelivingza/"><FontAwesomeIcon icon={faPinterest}/></a></li>
                </ul>
                <div className="sections">
                    <Link to="/restaurant">Restaurant</Link>
                    <Link to="/cafe">Cafe</Link>
                    <Link to="/bar">Bar</Link>
                    <Link to="/guides">Guides</Link>
                </div>
            </FooterWrapper>
            <Copyright>
                <p>&copy; Copyright 2020 - <a href="http://www.rathupap.co.za" rel="noreferrer" target="_blank">Prince Rathupa</a> - All Rights Reserved.</p>
            </Copyright>
        </Container>
    </Footer>
)

export default FooterComponent;
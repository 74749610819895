import styled from 'styled-components';

export const Footer = styled.footer`
    background-color: ${ props => props.theme.secondaryColor };
    font-family: ${ props => props.theme.primaryFont};
`

export const FooterWrapper = styled.div`
    padding: 2rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
        padding-bottom: 2rem;
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;

        li {

            margin-right: 1rem;

            &:last-child{
                margin-right: 0;
            }

            a {
                color: ${ props => props.theme.lightColor };
                padding: 0.5rem 0.7rem;
                background-color: ${ props => props.theme.primaryColor };
                border-radius: 50%;
                border: 1px solid transparent;
                transition: all 0.5s ease-in-out;

                &:hover {
                    color: ${ props => props.theme.primaryColor};
                    background-color: ${ props => props.theme.lightColor};
                    border: 1px solid ${ props => props.theme.primaryColor}
                }
            }
        }

        
    }

    .sections {
        display: flex;

        a {
            padding-left: 1rem;
            color: ${ props => props.theme.primaryColor };
        }
    }
`
export const Copyright = styled.div`
    padding-top: 1rem;
    border-top: 1px solid ${ props => props.theme.primaryColor };
    text-align: center;
    padding-bottom: 2rem;
    
    p {
        color: ${ props => props.theme.darkGreyColor };
        a {
            color: ${ props => props.theme.primaryColor };
        }
    }
`

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { GlobalStyles, theme } from './../../elements/SharedElements';
import FooterComponent from './../shared/FooterComponent';
import NavigationComponent from './../shared/NavigationComponent';

const Layout = (props) => {
    
    const keywords = props.tags || "Cafe Living, Eateries, Cafe, Coffee, Restaurant, Budget Meals, Bar, Cocktails";
    const description = props.description || "Café Living explores a plethora of eateries within the South African landscape. From stylish 5 star dining to budget meals.";

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <Helmet>
                <title>Cafe Living | { props.title }</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta name="google-site-verification" content="v4D0iVWtOW3b6wCkwdGyzhV2gTsEmhzLTRdsyGLtvJg" />
            </Helmet>
            <NavigationComponent />
            {props.children}
            <FooterComponent />
        </ThemeProvider>
        
    )
    
}

export default Layout;
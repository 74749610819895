import styled from 'styled-components';

export const Header = styled.div`
    background-color: ${ props => props.theme.secondaryColor };
`

export const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 5rem;
    font-family: ${ props => props.theme.primaryFont};

    div {

        a{
            color: ${ props => props.theme.primaryColor };
            font-size: 2rem;
            font-weight: bold;
        }

    }

    .desktop {
        display: flex;
        list-style-type: none;

        @media screen and (max-width: 768px){
            display: none;
            flex-direction: column;
        }

        a {
            text-decoration: none;
            margin-left: 1rem;
            padding-bottom: .3rem;
            color: ${ props => props.theme.primaryColor };

            &.active {
                border-bottom: 1px solid ${ props => props.theme.primaryColor }
            }

            &:hover {
                border-bottom: 1px solid ${ props => props.theme.primaryColor }
            }
        }

    }

    svg {
        color: ${ props => props.theme.primaryColor };
        display: none;
        position: relative;
        z-index: 5;

        @media screen and (max-width: 768px){
            display: block;
        }
    }

    .mobile {
        display: none;
        list-style-type: none;

        @media screen and (max-width: 768px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
            height: 100%;
            width: 100%;
            background-color: ${ props => props.theme.darkColor };
            opacity: 0.8;
        }

        li {
            margin-top: 1rem;
        }

        a {
            text-decoration: none;
            padding-bottom: .3rem;
            color: ${ props => props.theme.primaryColor };
            font-size: 1.2rem;

            &.active {
                border-bottom: 2px solid ${ props => props.theme.primaryColor }
            }

        }

    }

    i {
        color: ${ props => props.theme.primaryColor };
    }
`
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body {
        font-family: ${ props => props.theme.secondaryFont};
        line-height: 1.2;
        background-color: ${ props => props.theme.lightColor};
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: ${ props => props.theme.primaryFont};
    }

    a {
        text-decoration: none;
    }
`

export const Container = styled.div`
    max-width: 990px;
    margin: 0 auto;
`

export const theme = {
    primaryColor: "#e57667",
    secondaryColor: "#fcf8f7",
    darkColor: "#000",
    lightColor: "#fff",
    lightGreyColor: "#ccc",
    darkGreyColor: "#999594",
    primaryFont: "Playfair Display",
    secondaryFont: "Lato"
}